import React from 'react';
import './AboutPage.css';
import { Link } from 'react-router-dom';
import gotoTop from './img_assets/top.png';
import Logo from './img_assets/logo.svg'; 
import Characters from './img_assets/headCharacter.png'; 
import mozillalogo from './img_assets/mozilla.png'; 
import waaglogo from './img_assets/waag.png'; 
import twitterLogo from './img_assets/twitter.png'; 
import stimuleringlogo from './img_assets/stimuleringfund.png'; 

import scraperGif from './img_assets/theirtube_backend.gif'; 


function AboutPage(){
    return (
    <div>
        <div className = "aboutFade">
        <Link to ="/"><div className="backtoTop aboutTop" >
        <img src={gotoTop} alt="back" width="30" />
        </div></Link>
        <div className="circleButtonBig Twitter" >
        <a href="http://twitter.com/share?url=https://www.their.tube&amp;text=TheirTube : Youtube filter bubble simulator — Developed by @TomoKihara » Mozilla Awards Project" target="_blank" class="in_link sns">
        <img src={twitterLogo} className="twitter_logo" alt="about" width="25" height="25" />
        </a>
        </div>
        <div className = "about">
        <div className = "Answer"> 
        {/* <img src={Logo} className="toplogoabout" alt="Logo" /> */}
        {/* <img src={Characters} className="aboutCharacters" alt="Logo" /> */}

        [2022.Update]<i> — Due to the update on Youtube's system the project is no longer updated</i>
        <br/>
        <br/>

        <div className = "VideoContainers">
        <iframe src="https://player.vimeo.com/video/523489388?h=efa345f7fa&title=0&byline=0&portrait=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>        </div>
        <div className = "topTextabout">TheirTube provides a look inside other people’s recommendation bubbles on YouTube. 
        <br/>It is a Mozilla Awards 2020 project developed by Tomo Kihara</div>
        <div className = "Question">What is Theirtube?</div>
        Theirtube is a Youtube filter bubble simulator that provides a look into how videos are recommended on other people's YouTube. 
        Users can experience how the YouTube home page would look for six different personas. 
        Each persona simulates the viewing environment of real Youtube users who experienced being inside a recommendation bubble through recreating a Youtube account with a similar viewing history. 
        TheirTube shows how YouTube’s recommendations can drastically shape someone’s experience on the platform and, as a result, shape their worldview. 
        It is part of the <a className="link" target="_blank" href="https://foundation.mozilla.org/en/blog/examining-ais-effect-on-media-and-truth/"> Mozilla Creative Media Awards 2020</a> — art and advocacy project for examining AI's effect on media and truth, developed by <a className="link" target="_blank" href="https://twitter.com/tomokihara?lang=en"> Tomo Kihara </a>.
        <br/>

  
        <div className = "Question">How does it work?</div>
        Each of these TheirTube personas is informed by interviews with real YouTube users who experienced similar recommendation bubbles. 
        Six YouTube accounts were created in order to simulate the interviewees’ experiences. 
        These accounts subscribe to the channels that the interviewees followed, and watches videos from these channels to reproduce a similar viewing history and a recommendation bubble. 
        Everyday, TheirTube retrieves the recommendations that shows up on their Youtube home page. 
        You can go back and forth the dates to see different results by clicking on the arrow button and also see the viewing history of each persona by clicking "Watch History" button.        
        We are on the lookout for new and interesting types of recommendation bubbles; suggest ideas <a className="link" target="_blank" href="mailto:hello@tomokihara.com"> here</a> .
        <br/>
        <div className = "Question">Is the project open sourced?</div>
        <div className = "textVideoContainer">
            <div className = "textContainer">
            Yes it is. The different Youtube results are retrieved everyday using  <a className="link" target="_blank" href="https://github.com/puppeteer/puppeteer"> puppeteer </a> (headless browser) running on node.js and displayed in the frontend built on <a className="link" target="_blank" href="https://github.com/puppeteer/puppeteer"> React</a> .
            <br/>
            <br/>
            You can make your own persona with the open sourced code on  <a className="link" target="_blank" href="https://github.com/kihapper/TheirTube"> Github </a>
            </div>
            <div className = "gifContainer">
                <img src = {scraperGif} className = "scrapeGif"></img>
                </div>
        </div>
        <div className = "Question">Why are these recommendations so different ?</div>
        On an average day, people around the world watch one billion hours of video on YouTube. <a className="link" target="_blank" href="https://qz.com/1178125/youtubes-recommendations-drive-70-of-what-we-watch/">70%</a> of these videos are recommended by an AI, making each and every YouTube experience unique. These recommendations are good at maximizing user engagement (and ad revenue) by predicting what a user may watch, and suggesting it.  While recommendations can be useful, they can also reinforce the same points of view over and over again, trapping you inside a recommendation bubble. So if you're skeptical about climate change, YouTube can recommend even more content denying climate change — confirming the bias that you already have.
        <br/>
        <div className = "Question">Why did you start this project?</div>
        This whole project started when I was in a heated discussion with a person who thought climate change was a hoax and 9/11 was a conspiracy. Through conversations with him, I was surprised to learn that he thought everyone’s YouTube feed had the same information as his own feed. When we showed each other our YouTube homepages, we were both shocked. They were radically different. And it got me thinking about the need for a tool to step outside of information bubbles.
        <br/>
        <div className = "Question">What is the aim of the project?</div>
        Humans are biased by default, and any source of information that we make will always be biased — because it is made by humans. With traditional media, you can more easily discern the bias of the content and the source. It’s easy to compare Fox News against the New York Times. 
        On YouTube, however, there can be a billion different ways of how the information is shown. The problem with this? It is difficult to understand what kind of information bubble you are in, because there is no easy way to compare your YouTube experience to that of others.  
        <br/><br/>
        I think the proverb <i> “Fish discover water last”</i> can also be said about how we are blind to the nature of the recommendation bubble that we are in. 
        Nowadays with an AI curating almost all of what we see, the only way for a person to get a better perspective on their own media environment is to see what others' look like. 
        By offering a tool to understand what the other recommendation bubbles look like, we hope to help people to get a better perspective on their own recommendation bubbles.

        <div className = "Question">Why are the personas made from individuals and not a group?</div>
        We stuck to simulating the Youtube persona of a few individual we interviewed rather than sampling data from multiple people. 
        We chose this method because it shows the nature of how personalised recommendations work more accurately. 
        Guillaume Chaslot's project <a className="link" target="_blank" href="https://algotransparency.org/?date=10-06-2020&keyword="> AlgoTransparency </a> gives you a better grasp on how the recommendations works by showing the most recommended videos from a set of 1000+ information channels.

        <div className = "Question">Contact</div>
        For more questions about the project please contact <a className="link" target="_blank" href="mailto:hello@tomokihara.com"> here</a>
        <br/>
        The images and videos for press release is in this <a className="link" target="_blank" href="https://drive.google.com/drive/folders/1yrrpEWOpvfoP-yE1gYXX2zCWAJXX-hIz?usp=sharing"> folder</a>
        <div className = "Question">Credit</div>
        Concept, Design, Development : <a className="link" target="_blank" href="https://www.tomokihara.com/"> Tomo Kihara </a>
        <br/> 
        Character Illustration, Animation : <a className="link" target="_blank" href="https://www.behance.net/alexeenkopolina"> Polina Alexeenko </a> 
        <br/> 
        <br/>  
        Funded by : Mozilla Foundation 
        <br/>  
        <img src={mozillalogo} className="supportlogo" alt="Mozilla Logo" width="150"/>
        <br/>
        <br/>  
        Supported by :  Waag, Creative Industries Fund NL
        <br/>
        <img src={waaglogo} className="supportlogo" alt="Waag Logo" width="190"/>
        <img src={stimuleringlogo} className="supportlogo" alt="Stimulering Funds Logo" width="100"/>
        <br/> 
        <br/> 
        <br/> 
        </div>
        </div>
    </div>
    </div>
    );}

export default AboutPage;