import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route , Switch } from 'react-router-dom';
import Persona from './components/Persona.js';
import load_jsonData from './components/APIoffline_fallback_data.json';
import Landing from './components/Landing.js';
import AboutPage from './components/AboutPage.js';
import loadingBot from './components/img_assets/loading_bot.png';


const fallback_JSON = load_jsonData;

//getting a date (this code is for backup)
const d = new Date;
const dateIs = d.getDate();

// const url = "https://tomo-theirtube.herokuapp.com/1";
//const url = "http://localhost:3000/?startDate=0&durationDate=10";
const url = "https://tomo-theirtube.herokuapp.com/?startDate=0&durationDate=11";

function App() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [videoJson, setJson] = useState([]);
  const [ loading_screen_state, setActivated ] = useState(true);

  //set time out on the loading screen I hate this
  useEffect(() => {
    setTimeout(() => {setActivated(false)}, 2500)
  }, []);

  // AJAX request to get the JSON files.
  // Note: the empty array [] at the end means this useEffect will run once
  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          console.log("Got JSON response");
          console.log(result);
          setJson(result);
          setIsLoaded(true);
        },
        (error) => {
          console.log("!!————Error occured————Setting fallback JSON data in the front end!!");
          setJson(fallback_JSON);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return (
      <div>
        Fetch error from Theirtube server, Showing the last backup data... 
        <div>
      <BrowserRouter>
      <Switch>
        <Route
          path="/"
          exact={true}
          component={Landing}
        />
        <Route
          path="/about"
          exact={true}
          component={AboutPage}
        />

        <Route
          path="/:personaname"
          exact={true}
          render={({match})=>{
          return (<Persona　videoList={videoJson} botOption = {match.params.personaname} />)
          }}
        />
        </Switch>
      </BrowserRouter>
      </div>
      </div>);
  } else if (!isLoaded || loading_screen_state) {
    return (
      <div>
      <BrowserRouter>
        <Route
          path="/"
          exact={true}
          render={()=>{
          return (<div>
            <div className = "loadingBlank">
            <img src = {loadingBot} className = "loadbot_png" alt = "loading bot"></img></div>
            <Landing/>
            </div>
            )
          }}  
          />
        <Route
          path="/about"
          exact={true}
          render={()=>{
            return (<div>
              <div className = "loadingBlank">
              <img src = {loadingBot} className = "loadbot_png" alt = "loading bot"></img></div>
              </div>
              )
            }}  
        />
       <Route
          path="/:personaname"
          exact={true}
          render={({match})=>{
            return (<div>
              <div className = "loadingBlank">
              <img src = {loadingBot} className = "loadbot_png" alt = "loading bot"></img></div>
              </div>
              )
            }}  
        />
      </BrowserRouter>
      </div>
    );
  } else {
    return (
      <BrowserRouter>
      <Switch>
        <Route
          path="/"
          exact={true}
          render={()=>{
            return (<Landing/>)
            }}
          // component={Landing}
          //videoJson
        />
        <Route
          path="/about"
          exact={true}
          component={AboutPage}
        />

        <Route
          path="/:personaname"
          exact={true}
          render={({match})=>{
          return (<Persona　videoList={videoJson} botOption = {match.params.personaname} />)
          }}
        />
        </Switch>
      </BrowserRouter>
    );
  }


}

export default App;



