import React from 'react'
import Logo from './img_assets/logo.svg'; 
import { BrowserRouter, Route , Link } from 'react-router-dom';
import './Landing.css';
import aboutMark from './img_assets/about.svg';
import AnimateBotAll from './AnimateBotAll.js'


function Landing(props) {


    let botWidth = 200;
    if(window.innerWidth>1700){
        botWidth = 250;
    }
    else if(window.innerWidth>1400 && window.innerWidth<1700){
        botWidth = 210;
    }
    else if(window.innerWidth>700 && window.innerWidth<1400){
        botWidth = 180;
    }
    else if(window.innerWidth<700){
        botWidth = 140;
    }

    return (
        <div >
            <div className = "landingAnimation">
        <Link to ="/about"><div className="circleButtonBig" >
        <img src={aboutMark} alt="about" width="20" height="20" />
        </div></Link>
        <div className = "landing">
            <div className = "topSection">
            <img src={Logo} className="toplogo" alt="Logo" />
                <div className = "topText">How do the recommended videos look on their Youtube home page?</div>            
            </div>
        <AnimateBotAll size={botWidth} />
        </div>
        </div>
        </div>
    );
}

export default Landing;