import React, { Component } from 'react'
// import Fruitarian from './img_assets/fruitarian.gif'
// import Leftist from './img_assets/leftist.gif'
// import Conservative from './img_assets/conservative.gif'
// import Conspiracist from './img_assets/conspiracist.gif'
// import ClimateDenier from './img_assets/climate_denialist.gif'
// import Prepper from './img_assets/prepper.gif'

class AnimateBot extends Component {
  render() {
    let gifTarget;
    let bot_size = this.props.size

    //this.props.option holds the value below
    switch (this.props.option) {
      case "alt_right":
        gifTarget = "/animations/conservative.gif";　
        break;
      case "body_builder":
        break;
      case "climate_deniers":
        gifTarget = "/animations/climate_denialist.gif";
        break;
      case "egg_opener":
        break;
      case "flusher":
        break;
      case "fruitarian":
        gifTarget = "/animations/fruitarian.gif";
        break;
      case "leftist":
        gifTarget = "/animations/leftist.gif";
        break;
      case "prepper":
        gifTarget =  "/animations/prepper.gif";
        break;
      case "conspiracist":
        gifTarget = "/animations/conspiracist.gif";
        break;
      default:
    }


    return (
      <div>
        <img src = {gifTarget} alt = "bots" width = {bot_size} ></img>
      </div>
    )
  }
}

export default AnimateBot