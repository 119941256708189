import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SingleVideo from './SingleVideo.js';
import './Persona.css';
import AnimateBot from './AnimateBot.js';
import botsInfo from './botsInfo.json';
import aboutMark from './img_assets/about.svg';


//importing images for the semi-youtube bar
import logoLeft from './img_assets/theirtube_inside.png';
import searchCenter from './img_assets/2search.jpg';
import iconsRight from './img_assets/3icons.jpg';
import right_arrow from './img_assets/ArrowRight.png';
import left_arrow from './img_assets/ArrowLeft.png';
import history from './img_assets/history.png';
// import goback from './img_assets/back.svg';
import gotoTop from './img_assets/top.png';


function Persona(props) {

    //hooks to update each video
    const [current_video_array, setVideoarray] = useState(0);

    //incrementer for each video date
    const [diff_date, setDiffDate] = useState(0);
  

  //props.botOption receives what kind of bot to show
  //props.videoList receives all the JSON data from the server

  let current_bot = props.botOption;

  if( props.botOption === "conservative"){
    current_bot = "alt_right"
  }

  //Date of the shown videos in the youtube list

  let currentDate = new Date();
  // hooks to update the date of the viewer — date, month, Year
  const [viewerDate, setViewerDate] = useState([currentDate.getDate(), currentDate.getMonth(), currentDate.getFullYear()]);

  //month array for display
  const months = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];


  const forwardDate = () => {
    if (current_video_array > 0) {
      // setDiffDate(diff_date + 1);
      // console.log("FORWARD : diff_date is " + diff_date);

      setVideoarray(current_video_array - 1);
      // console.log("FORWARD : current_video_array is " + current_video_array);
      //updates the current date based on where the video array is
      // currentDate.setDate(currentDate.getDate() + diff_date);
      // setViewerDate([currentDate.getDate(), currentDate.getMonth(), currentDate.getFullYear()])
    }
  }

  const previousDate = () => {
    if (current_video_array < 10) {

      //props.videoList[current_bot].video_array.length - 1
      //updates the current date based on where the video array is
      setDiffDate(diff_date - 1);
      // console.log("PREVIOUS : diff_date is " + diff_date);

      setVideoarray(current_video_array + 1);
      // console.log("PREVIOUS : current_video_array is " + current_video_array);

      // currentDate.setDate(currentDate.getDate() + diff_date);
      // setViewerDate([currentDate.getDate(), currentDate.getMonth(), currentDate.getFullYear()])
      //viewerDate.setDate(viewerDate.getDate() + 1);
    }
  }

  //Object destructuring to put the info in botInfo.json in separate variables
  //This documentation helped https://wesbos.com/destructuring-objects
  const { name, description, trained_upon, channel_url, persona, prop_code } = botsInfo[current_bot];

  //console.log(props.videoList[current_bot].video_array);
  let current_videolist = props.videoList[current_bot].video_array.slice(0, 30);
  current_videolist = current_videolist[current_video_array];


  //Date cut by GMT+, there should be better way, later fix
  let displayDate = current_videolist.date.split(" ");
  let timeDisplay = displayDate[4].split(":");
  console.log(timeDisplay);

  //let shortDate = "Sun May 31 2020 14:50:10 GMT+0100 (British Summer Time)";

  console.log("GENERAL : current_video_array is " + current_video_array);
  console.log("GENERAL : diff_date is " + diff_date);


  return (
   <div>
    <div className="personaAll">
        <Link to ="/about"><div className="circleButtonBig" >
        <img src={aboutMark} alt="about" width="20" height="20" />
        </div></Link>
        <Link to ="/"><div className="backtoTop" >
        <img src={gotoTop} alt="back" width="30" />
        </div></Link>

      <div className="personaInfo">

      <div className="box_bot">
          <AnimateBot option={current_bot} size={180} />
        </div>

        <div className="box_title">
          <div className="mainHead" >{name}</div>
          <div className="text">{description}</div>
        </div>

        <div className="box_trained">
          <div className="subHead" >Watched videos from...</div>
          <div className="text">{trained_upon}</div>
          {/* <a className="link" href={channel_url} target="_blank">Go see training history</a> */}
        </div>


      </div>
      <div className="allContainer">
        <div className="topbar">
          <div className="front_back">
            <div className="circleButton" onClick={previousDate}>
              <img src={left_arrow} alt="back" width="14" height="14" /></div>
            <div className="circleButton" onClick={forwardDate}>
              <img src={right_arrow} alt="forward" width="14" height="14" /></div>
          </div>
          <div className="tab_bar">
          <div className="retrievedOn">Retrieved :  </div>
          <div className="date_text"> {displayDate[1] + " " +displayDate[2]} </div>
          <div className="time_text"> {"— " + timeDisplay[0] + ":" +timeDisplay[1]} </div>
          </div>
          <div className="history_block">
          <div className="history_text"> Watch History</div>
          <a href={channel_url} target="_blank" alt="history">
          <div className="circleButton historyButton">
          <img src={history} alt="back" width="18" height="18" />
          </div>
          </a>
          </div>
        </div>

        <div className="youtubeBar">
          <img src={logoLeft} className="leftlogo_pic" height ="45"alt="icons" />
          <img src={searchCenter} className="searchbar_pic" alt="icons" />
          <img src={iconsRight} className="rightitem_pic" alt="icons" />
        </div>
        <div className="videosContainerCentering">
        <div className="videosContainer">
          {
            current_videolist.videos.map((eachVideo) => {
              return (
                <SingleVideo videoDetails={eachVideo} />
              );
            })
          }
        </div>
        </div>
      </div>


    </div>
    </div>

  );
}

export default Persona;
