import React from 'react'
import AnimateBot from './AnimateBot';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import './Landing.css';



function AnimateBotAll(prop) {

  const botSize = prop.size;

  //Applying CSS Styles to the wrapper
  

  return (
    <div>
      <div className = "botWrapper">
        <Link className="hoverPersona" to="/fruitarian">
          <AnimateBot option='fruitarian' size={botSize} />Fruitarian</Link>
        <Link className="hoverPersona" to="/prepper">
          <AnimateBot option='prepper' size={botSize} />Prepper</Link>
        <Link className="hoverPersona" to="/leftist">
          <AnimateBot option='leftist' size={botSize} />Liberal</Link>
        <Link className="hoverPersona" to="/conservative">
          <AnimateBot option='alt_right' size={botSize} />Conservative</Link>
        <Link className="hoverPersona" to="/conspiracist">
          <AnimateBot option='conspiracist' size={botSize} />Conspiracist</Link>
        <Link className="hoverPersona" to="/climate_deniers">
          <AnimateBot option='climate_deniers' size={botSize} />Climate Denier</Link>
      </div>
    </div>
  );
}




export default AnimateBotAll;