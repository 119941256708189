import React from 'react';
import './SingleVideo.css';

//component that shows single video
//TODO : Use object destructuring to make this more clear
//Parse the things after ? to make it also work in Safari

function SingleVideo(props) {

    //Object Destrutiring to store all these variables...
    const { url,thumbnail,channel_url,channel_icon,title,channel,viewnum,date } = props.videoDetails;
    let thumbnail_jpg = thumbnail.split("?");


    return (
        <div className="videoContainer">
            <div className="thumbnailContainer">
                <a href={url} target="_blank" rel="noopener noreferrer">
                    <img src={thumbnail_jpg[0]} className ="thumbnail" alt="test" />
                </a>
            </div>

            <div className="infoContainer">
                <div className="iconContainer">
                    <a href={channel_url} target="_blank" rel="noopener noreferrer">
                        <img src={channel_icon} className="channel_icon" alt="test" />
                    </a>
                </div>

                <div className="titlesContainer">
                    <div className="titleContainer">
                        <a href={url} target="_blank" rel="noopener noreferrer" > <div className="title">{title}</div></a>
                    </div>
                    <a href={channel_url} target="_blank" rel="noopener noreferrer" > <div className="small_text">{channel}</div></a>
                    <div className="numberContainer">
                        <div className="small_text">{viewnum}</div>
                        <div className="small_text">{"・"}{date}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleVideo;
